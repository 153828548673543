import Vue from 'vue';
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';
import VueAxios from 'vue-axios';
import axios from 'axios';
import moment from 'moment';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

Vue.use(VueLodash, { lodash });
Vue.router = router;
// Vue.use(router);
Vue.use(VueAxios, axios);
// Vue.config.devtools = true;

// Vue.axios.defaults.baseURL = 'https://api.leads.bkr-digital.com/api';
// Vue.axios.defaults.baseURL = 'https://api.leads.winindeal.com/api';
// https://leads.topstretching.me/#/login
Vue.axios.defaults.baseURL = 'https://api.leads.topstretching.me/api';
// Vue.axios.defaults.baseURL = 'http://127.0.0.1:8000/api';

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: {
      request(req, token) {
        const parsedToken = token.split(';');
        // console.log(token, parsedToken);
        if (req.url === this.options.refreshData.url) {
          // console.log(this.options);
          // eslint-disable-next-line no-param-reassign
          req.data = {
            access_token: parsedToken[0],
            refresh_token: parsedToken[1],
          };
        }

        this.drivers.http.setHeaders.call(this, req, {
          Authorization: `Bearer ${parsedToken[0]}`,
        });
      },
      response(res) {
        const resData = res.data || {};
        // console.log(resData);
        // this.token('refresh_token', resData.refresh_token);
        if (res.data.access_token && res.data.refresh_token) {
          return `${resData.access_token};${resData.refresh_token}`;
        }
        return null;
      },
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'role',
    refreshData: {
      url: '/refresh-token',
      method: 'POST',
      enabled: true,
      interval: 5,
    },
    fetchData: {
      url: '/users/me',
      method: 'GET',
    },
    loginData: {
      url: '/login',
      method: 'POST',
      redirect: '/dashboard',
      fetchUser: true,
    },
    logoutData: {
      url: '/logout',
      method: 'POST',
      redirect: '/login',
      makeRequest: false,
    },
  },
  refresh_token: '',

});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
